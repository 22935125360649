import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import PartnersSection from '@components/pages/partners/partners';
import JoinSection from '@components/pages/partners/join';

class PartnersPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.cockpitpartners[section].childMarkdownRemark.html;
  }

  render() {
    const { data } = this.props;
    return (
      <MainLayout>
        {/* TODO: add keywords */}
        <SEO title="Global partners" keywords={[]} />
        {/* <PartnersSection content={this.getSectionContent('partners')} data={data.allCockpitpartnersLogos.edges} /> */}
        <JoinSection content={this.getSectionContent('join')} />
      </MainLayout>
    );
  }
}

PartnersPage.propTypes = {
  data: PropTypes.object,
};

export default PartnersPage;

export const query = graphql`
  query PartnersQuery {
    cockpitpartners {
      partners: childCockpitpartnersPartnersTextNode {
        childMarkdownRemark {
          html
        }
      }
      join: childCockpitpartnersJoinTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allCockpitpartnersLogos {
      edges {
        node {
          entry {
            name
            logo {
              path
            }
          }
        }
      }
    }
  }
`;
