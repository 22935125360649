import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 142,
  '@media (max-width: 480px)': {
    marginBottom: 50,
  },
});

export const ContentWrapper = styled.div({
  padding: '100px 10% 0',
  textAlign: 'center',
  '& h2': {
    marginBottom: 20,
  },
  '& h5': {
    width: '40%',
    margin: '0 auto',
    marginBottom: 30,
    lineHeight: '22px',
  },
  '@media (max-width: 480px)': {
    '& h2': {
      marginBottom: 28,
      fontSize: 25,
      lineHeight: '29px',
    },
    '& h5': {
      width: '100%',
      marginBottom: 40,
      lineHeight: '19px',
    },

  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const StyledForm = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 480px)': {
    '& button': {
      minWidth: 69,
      maxWidth: 69,
      height: 32,
      fontSize: 12,
    },
  },
});

export const StyledInput = styled.input({
  width: 457,
  height: 32,
  marginRight: 17,
  padding: '0 10px',
  borderRadius: 5,
  border: 'none',
  outline: 'none',
  fontSize: 17,
  '@media (max-width: 480px)': {
    fontSize: 15,
    width: '101%',
    marginRight: '-1%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
},
({ theme }) => ({
  color: theme.colors.textGray,
  backgroundColor: theme.colors.textInputBackground,
  '&::placeholder': {
    color: theme.colors.textLightGray,
  },
}));
