import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  paddingTop: 50,
  marginBottom: 265,
  '@media (max-width: 480px)': {
    marginBottom: 115,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

export const TextContainer = styled.div({
  marginBottom: 65,
  width: '50%',
  '& h2': {
    marginBottom: 20,
  },
  '& h4': {
    lineHeight: '25px',
  },
  '@media (max-width: 480px)': {
    width: '100%',
    marginBottom: 85,
    '& h2': {
      marginBottom: 25,
    },
    '& h4': {
      fontSize: 15,
      lineHeight: '19px',
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4': {
    color: theme.colors.textGray,
  },
}));

export const LogosContainer = styled.div({
  width: '70%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'auto',
  gridGap: '50px 5%',
  '@media (max-width: 480px)': {
    width: '100%',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '28px 10%',
  },
});

export const StyledImage = styled.img({
  width: '100%',
  filter: 'grayscale(100%)',
  '&:hover': {
    filter: 'grayscale(0)',
  },
  transition: 'filter 0.5s',
  '@media (max-width: 480px)': {
    filter: 'none',
  },
});
