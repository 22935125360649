import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper, ContentWrapper, TextContainer, LogosContainer, StyledImage,
} from './styles';

const PartnersSection = ({ content, data }) => (
  <Wrapper>
    <ContentWrapper>
      <TextContainer dangerouslySetInnerHTML={{ __html: content }} />
      <LogosContainer>
        {data.map(({ node: { entry } }, index) => (
          <StyledImage src={`http://cockpit.novotalk.com${entry.logo.path}`} alt={entry.name} key={index} />
        ))}
      </LogosContainer>
    </ContentWrapper>
  </Wrapper>
);

PartnersSection.propTypes = {
  content: PropTypes.string,
  data: PropTypes.object,
};

export default PartnersSection;
