import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { submitFormData } from '@utils/hubspot';
import { isEmailValid } from '@utils/helpers';

import Button from '@components/button';
import {
  Wrapper, ContentWrapper, StyledForm, StyledInput,
} from './styles';

class JoinSection extends Component {
  constructor() {
    super();
    this.state = {
      inputValue: '',
    };
  }

  handleInput = (e) => {
    const { value } = e.target;
    this.setState({ inputValue: value });
  }

  handleSubmit = (e) => {
    const { inputValue } = this.state;
    e.preventDefault();
    if (isEmailValid(inputValue)) {
      submitFormData({
        email: inputValue,
        page: 'Partners',
      });
      this.setState({ inputValue: '' });
    }
  }

  renderForm = () => {
    const { inputValue } = this.state;
    return (
      <StyledForm>
        <StyledInput type="text" onChange={this.handleInput} value={inputValue} placeholder="Email" />
        <Button
          type="submit"
          onClick={this.handleSubmit}
          width={130}
          height={32}
        >
          Send
        </Button>
      </StyledForm>
    );
  };

  render() {
    const { content } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {this.renderForm()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

JoinSection.propTypes = {
  content: PropTypes.string,
};

export default JoinSection;
